import { currentLocale, setLocale } from '@aspect/shared/plugins/i18n.ts';

export default function useChangeLocale() {
    const opposingLocales = {
        en: {
            code: 'fr',
            name: 'Français',
        },
        fr: {
            code: 'en',
            name: 'English',
        },
    };

    return {
        opposingLocales,
        swapLocale() {
            const newLocale = opposingLocales[currentLocale.value].code;
            setLocale(newLocale);
        }
    };
}
